.App {
  text-align: center;
}
.block-image{
  width: 100%;
}
.block-container{
  width:25%;
}
.block-content{

}
.block-heading{
  margin-left: -10px;
}
.blocks-container{
  width: 78vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    margin-top: 40px;
}
.block-heading{
  font-weight: 550;
    margin-bottom: 20px;
    font-style: normal;
    font-size: 21px;
    background: #d9261d;
    color: white;
    margin-left: 1px;
    font-family: sans-serif;
    text-transform: uppercase;
    padding: 4px;
    border-radius: 2px;
    background: linear-gradient(359deg, #8e2424, #d9261c);
}

.business-hours{
  text-align: right;
  width:20vw;
}
.business-hours h1{
  text-align: center;
}
.business-hours li{
  list-style-type: none;
  margin-bottom: 10px;
  margin-right: 10px;
}
.buzztext{
  font-style: italic;
  color: #0a6fc8;
}
.day {
  width: 100px;
}
.email-form{
  display: flex;
  flex-direction: column;
  width:25vw;
}

/* .email-form input, textarea, button{
  border-radius: 5px;
  padding:3px;
  margin-top:5px;
  width:100%;
} */
.email-form input[type='text'], .email-form input[type='tel']
{
  border-radius: 5px;
  padding:7px;
  margin-top:5px;
  margin-bottom: 10px;
  width:95%;
}
.email-form textarea {
  height: 200px;
  padding:7px;
  margin-top:5px;
  margin-bottom: 10px;
  width:95%;
}
.email-submit-btn{
  width:85%;
  margin: 20px;
  background-color: #3d66a2;
  color: white;
  height: 50px;
  background: linear-gradient(45deg, #9dc0f9, #3d67a2);
  font-size: larger;
  border-radius: 5px;
}
.email-submit-btn:hover{
  border-style: double;
  cursor: pointer;
}
.facebook-btn{
  width: 87%;
    margin: 20px;
    color: white;
    height: 65px;
    background: linear-gradient(45deg, #f4f6f9, #edf1f6);
}
.facebook-btn:hover{
  border-radius: 10px;
  cursor: pointer;
}
.footer{
  display: flex;
    flex-direction: row;
    margin-top: 40px;
    width: 85vw;
    justify-content: space-between;
    margin: auto;
    padding-top: 40px;
}
.footer-logo{
  display: flex;
  width:25vw;
  justify-content: center;
  margin: auto;
}
.footer-logo img{
  width:100%;
  margin-top: 25px;
}
h1{
  background: linear-gradient(45deg, #9dc0f9, #3d67a2);
    padding: 21px;
    color: #eceef1;
    border-radius: 50px 50px 0px 0px;
}
.footer h1{
  font-weight: 300;
}
hr{ 
  background: #3163a4; 
  margin: 10px auto; 
  padding: 2px; 
  width: 80%; 
  margin: auto;
  margin-top: 25px;
}
img{
  border-radius: 5px;
}
.logo {
  pointer-events: none;
  display: flex;
}
.logo img{
  width:25vw;
  max-width: 25vw;
  padding:20px;
}
.map{
  width: 30vw;
  height: auto;
}
.map img{
  width: 100%;
    border-color: #3d67a2;
    border-style: inset;
    border-width: 2px
}
.map img:hover{
  border-color: #d72c1c;
  border-style:double;
  cursor: pointer;
}
@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image-slider-container{
  width:83vw;
  margin:auto;
}
.image-slider-item {
  display: none;
}
.image-slider-item img{
  display: flex;
max-height: 300px;
height: 300px;
object-fit: cover;
}
.image-slider-item.active {
  display: block;
}
.main-content{
  width:85vw;
  margin:auto;
}
.main-content p{
  text-align: justify;
}
.main-content h1{
  font-weight: 300;
  text-shadow: 1px 1px 0 #0c0c0c21, -1px 0px 0 #1501010d, 1px 2px 0 #73575708, 0px -1px 0 #f4eded29;
}
.service-list{

}
.times{
  color: brown;
  margin-left: auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotatey(180deg);
  }
  to {
    transform: rotatey(360deg);
  }
}

@media only screen and (max-width: 800px) {
  .footer{
    flex-direction: column;
  }
  .email-form{
    width: 80vw;
    margin: auto;
  }
  .business-hours{
    width:80vw;
    margin: auto;
    text-align: center;
  }
  .business-hours li{
    
    font-weight: 900;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -15px;
  }
  .logo img{
    width:88vw;
    max-width: 88vw;
    padding:20px;
  }
  .map{
    width: 100%;
  }
  .blocks-container{
    flex-direction: column;
  }
  .block-container{
    width: 80vw;
  }
}